import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import UsherLayout from '../layouts/UsherLayout.vue'
import { useAuthStore } from '../stores/auth.store'
import { useAuthStore as useUsherAuthStore } from '../stores/usher-auth.store'
import UsherAuthLayout from '../layouts/UsherAuthLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

const validateAuth = async () => {
  const authStore = useAuthStore()
  return await authStore.isLoggedInThenReturnBool()
}

const validateUsherAuth = async () => {
  const usherAuthStore = useUsherAuthStore()
  return await usherAuthStore.isLoggedInThenReturnBool()
}

const handleLogout = async () => {
  const authStore = useAuthStore()
  await authStore.logout()
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/CustomDashboard.vue'),
        beforeEnter: async (to, from) => {
          if (!(await validateAuth())) {
            return { path: '/auth/login' }
          }
        },
      },
      {
        name: 'events',
        path: 'events',
        component: () => import('../pages/events/EventsPage.vue'),
        beforeEnter: async (to, from) => {
          if (!(await validateAuth())) {
            return { path: '/auth/login' }
          }
        },
      },
      {
        name: 'events management',
        path: 'events/:id/management',
        component: () => import('../pages/events/EventManagementPage.vue'),
        beforeEnter: async (to, from) => {
          if (!(await validateAuth())) {
            return { path: '/auth/login' }
          }
        },
      },
      {
        name: 'events guest',
        path: 'events/:id/guest',
        component: () => import('../pages/guests/GuestsPageWithEventId.vue'),
        beforeEnter: async (to, from) => {
          if (!(await validateAuth())) {
            return { path: '/auth/login' }
          }
        },
      },
      {
        name: 'events guest group',
        path: 'events/:id/guest/groups',
        component: () => import('../pages/guests/GuestsGroupPageWithEventId.vue'),
        beforeEnter: async (to, from) => {
          if (!(await validateAuth())) {
            return { path: '/auth/login' }
          }
        },
      },
      {
        name: 'events usher',
        path: 'events/:id/usher',
        component: () => import('../pages/usher/UsherPageWithEventId.vue'),
        beforeEnter: async (to, from) => {
          if (!(await validateAuth())) {
            return { path: '/auth/login' }
          }
        },
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'whatsapp broadcast',
        path: 'events/:id/broadcast/whatsapp',
        component: () => import('../pages/whatsapp_broadcast/WhatsappBroadcast.vue'),
        beforeEnter: async (to, from) => {
          if (!(await validateAuth())) {
            return { path: '/auth/login' }
          }
        },
      },
    ],
  },
  {
    path: '/auth',
    component: UsherAuthLayout,
    children: [
      {
        name: 'usher-login',
        path: 'usher-login',
        component: () => import('../pages/auth/UsherLogin.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'logout',
        path: 'logout',
        component: () => import('../pages/auth/Login.vue'),
        beforeEnter: async (to, from) => {
          if (await validateAuth()) {
            await handleLogout()
          }

          return { path: '/auth/login' }
        },
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    path: '/usher',
    component: UsherLayout,
    children: [
      {
        name: 'usher-dashboard',
        path: 'dashboard',
        component: () => import('../pages/usher/Dashboard.vue'),
      },
      {
        name: 'usher-guest',
        path: 'events/guest',
        component: () => import('../pages/guests/UsherGuestsPageWithEventId.vue'),
        beforeEnter: async (to, from) => {
          if (!(await validateUsherAuth())) {
            return { path: '/auth/usher-login' }
          }
        },
      },
      {
        name: 'usher-logout',
        path: 'logout',
        component: () => import('../pages/auth/UsherLogin.vue'),
        beforeEnter: async (to, from) => {
          const usherAuthStore = useUsherAuthStore()
          await usherAuthStore.logout()

          return { path: '/auth/usher-login' }
        },
      },
      // {
      //   name: 'usher-checkin',
      //   path: 'checkin',
      //   component: () => import('../pages/usher/CheckIn.vue'),
      //   beforeEnter: async (to, from) => {
      //     if (!(await validateUsherAuth())) {
      //       return { path: '/auth/usher-login' }
      //     }
      //   },
      // },
      {
        name: 'usher-checkout',
        path: 'checkout',
        component: () => import('../pages/usher/CheckIn.vue'),
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    name: 'usher-checkin',
    path: '/usher/checkin',
    component: () => import('../pages/usher/CheckInV3.vue'),
    beforeEnter: async (to, from) => {
      if (!(await validateUsherAuth())) {
        return { path: '/auth/usher-login' }
      }
    },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
